<template>
  <div class="VolunteerList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
      :isMultiSelect="false"
    >
      <template #headerSlot>
        <v-button v-if="showBtn" permission="add" text="新增" @click="toAddPages" />
        <v-button text="导入" permission="import" @click="batchImport" />
      </template>
      <template #searchSlot>
        <v-input label="姓名" v-model="searchParams.volunteerName"></v-input>
        <v-input label="手机号" v-model="searchParams.phone"></v-input>
        <v-select2
          v-if="getShowRegionName()"
          label="所属公司"
          v-model="searchParams.regionId"
          v-bind="regionParams"
          @onChange="onChange"
        ></v-select2>
        <v-select2
          label="所属项目"
          v-model="searchParams.communityId"
          v-bind="communityParams"
          @onChange="onChange"
          :subjoin="communitySubjoin"
        ></v-select2>
        <v-select
          label="服务大类"
          v-model="searchParams.typeId"
          :options="typeIdOps"
          :disabled="!searchParams.regionId && !searchParams.communityId"
        ></v-select>
        <v-input
          label="参与岗位"
          v-model="searchParams.volunteerDuty"
        ></v-input>
        <v-select
          label="审核状态"
          v-model="searchParams.isExamine"
          :options="statusOps"
        ></v-select>

        <v-datepicker
          label="申请时间段"
          :maxDate="maxDate"
          :startTime.sync="searchParams.startDate"
          :endTime.sync="searchParams.endDate"
        ></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="编辑"
          type="text"
          permission="audit"
          @click="edit(scope.row)"
          v-if="!getShowRegionName()"
        ></v-button>
        <v-button
          text="查看"
          type="text"
          @click="look(scope.row)"
          v-if="getShowRegionName()"
        ></v-button>
      </template>
    </list>
  </div>
</template>
<script>
import { getListURL, getTypeIdURL } from "./api";
import { statusMap, setStatusOps } from "./map";
import { communityParams, regionParams } from "@/common/select2Params";

export default {
  name: "VolunteerList",
  data() {
    return {
      searchUrl: getListURL,
      statusOps: setStatusOps(1),
      communityParams,
      regionParams,
      showBtn: false,
      maxDate: new Date(),
      typeIdOps: [
        {
          text: "全部",
          value: undefined,
        },
      ],
      searchParams: {
        volunteerName: "",
        phone: "",
        typeId: undefined,
        volunteerDuty: "",
        isExamine: undefined,
        communityId: "",
        startDate: "",
        endDate: "",
        regionId: "",
      },
      headers: [
        {
          prop: "volunteerPic",
          label: "照片",
          formatter: (row) => {
            return this.$createElement("img", {
              attrs: {
                src: row.volunteerPic,
              },
              style: {
                width: "48px",
                height: "48px",
              },
            });
          },
        },
        {
          prop: "volunteerName",
          label: "姓名",
        },
        {
          prop: "phone",
          label: "联系方式",
        },
        {
          prop: "volunteerCat",
          label: "服务大类",
        },
        {
          prop: "volunteerDuty",
          label: "参与岗位",
        },
        {
          prop: "status",
          label: "审核状态",
          formatter(row) {
            return statusMap[row.status];
          },
        },
        {
          prop: "intime",
          label: "申请时间",
        },
        {
          prop: "praiseCount",
          label: "被点赞",
          align: "right",
        },
        {
          prop: "helpCount",
          label: "被求助",
          align: "right",
        },
        {
          prop: "communityName",
          label: "所属项目",
        },
        {
          prop: "regionName",
          label: "所属公司",
          show: this.getShowRegionName(),
        },
      ],
      roleId: null,
    };
  },
  created() {
    // this.getTypeIdData()
    const { status } = this.$route.query;

    let userType = this.$store.state.userInfo.userType;

    console.log(userType, '___userType')
    console.log(this.$store.state.userInfo, 'userInfo')
    if(userType === '102' || userType === '101') {
      this.showBtn = true
      this.roleId = this.$store.state.userInfo.roleId
    } else {
      this.showBtn = false
    }
    if (status !== undefined) {
      // 清空之前筛选项
      this.searchParams = {
        volunteerName: "",
        phone: "",
        typeId: undefined,
        volunteerDuty: "",
        isExamine: undefined,
        communityId: "",
        startDate: "",
        endDate: "",
        regionId: "",
      };
      this.searchParams.isExamine = status;
    }
  },
  computed: {
    communitySubjoin() {
      return {
        regionId: this.searchParams.regionId,
      };
    },
  },
  methods: {
    // 根据账户角色权限，获取是否要显示所属分公司
    getShowRegionName() {
      let userInfo = this.$store.state.userInfo;
      let show = false;
      if (userInfo) {
        if (
          userInfo.userType &&
          (userInfo.userType === "100" || userInfo.userType === "106")
        ) {
          show = true;
        }
      }
      return show;
    },
    getTypeIdData() {
      const _this = this;
      _this.typeIdOps = [
        {
          text: "全部",
          value: undefined,
        },
      ];
      let params = {
        tenantId: this.searchParams.regionId,
        communityId: this.searchParams.communityId,
      };
      for (let k in params) {
        if (!params[k]) {
          delete params[k];
        }
      }
      this.$axios.get(getTypeIdURL, { params }).then((res) => {
        if (res.status == "100") {
          const data = res.data;
          data.forEach((item) => {
            _this.typeIdOps.push({
              text: item.categoryName,
              value: item.id,
            });
          });
        }
      });
    },
    onChange(val) {
      this.searchParams.typeId = undefined;
      if (val) {
        this.getTypeIdData();
      }
    },
    create() {
      this.$router.push({
        name: "volunteerForm",
      });
    },
    edit(row) {
      this.$router.push({
        name: "volunteerForm",
        query: {
          id: row.id,
        },
      });
    },
    look(row) {
      this.$router.push({
        name: "volunteerForm",
        query: {
          id: row.id,
          look: 1,
        },
      });
    },
    // 新增
    toAddPages() {
      this.$router.push({
        name: "volunteerAddNewForm",
        query: {
          roleId : this.roleId
        }
      });
    },
    // 导入
    batchImport() {
      this.$router.push({
        name: "importVolunteerPage",
      });
    },
  },
};
</script>
