import { mapHelper } from 'common/utils'

// 状态
const status = [
  {
    text: '已确认',
    value: 1
  },
  {
    text: '待确认',
    value: 0
  },
  {
    text: '未通过',
    value: 2
  }
]

const {
  map: statusMap,
  setOps: setStatusOps
} = mapHelper.setMap(status)

export {
  statusMap,
  setStatusOps
}
